import type { FlattenSimpleInterpolation } from 'styled-components';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';
import { font } from 'app/shared/styles/_fontsize';
import { onHover, smoothTransition } from 'app/shared/styles/_mixins';
import { zindex } from 'app/shared/styles/_zindex';

export const Link = styled.a`
  ${font.md}
  display: inline-block;
  margin: 0px;
  border-radius: 4px;
  width: auto;
  font-weight: bold;
  cursor: pointer;
  height: 48px;
  color: ${colors['$hpx-white']};
  background-color: ${colors['$hpx-teal-500']};
  padding: 12px 32px;
  z-index: ${zindex['$z-index-max']};
  ${smoothTransition}
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: -100px;
  white-space: nowrap;
  ${onHover(
    `
        color: ${colors['$hpx-white']};
    ` as unknown as FlattenSimpleInterpolation,
  )}
  &:active {
    color: ${colors['$hpx-white']};
  }
  &:focus {
    top: 4px;
    background-color: ${colors['$hpx-teal-600']};
  }
`;
