import React from 'react';
import loadable from '@loadable/component';

const HeaderNavMdAndUp = loadable(
  () =>
    import(
      /* webpackChunkName: "HeaderNavMdAndUp.loadable" */ 'app/shared/modules/navigation/header/HeaderNavMdAndUpComponent'
    ),
);

interface Props {
  loggedIn: boolean;
  unreadMsgCount: number;
  onOpenHamburgerNav: () => void;
  onCloseHamburgerNav: () => void;
  userInfo: any;
}

const LoadableHeaderNavMdAndUp = (props: Props) => {
  return <HeaderNavMdAndUp {...props} />;
};

export default LoadableHeaderNavMdAndUp;
