import isEmpty from 'lodash/isEmpty';

interface ConversationMessage {
  isMessageOwner: boolean;
  [key: string]: any; // Allow for other properties in the conversation message
}

interface ConversationDetails {
  conversation?: Array<ConversationMessage>;
  conversationId?: string;
  hasUnreadMessage?: boolean;
  isMultifamily?: boolean;
  mostRecentMessageTimestampMs?: number;
}

class Conversation {
  lastSender?: 'user' | 'landlord' | null;
  id?: string;
  lastMessageTimestamp?: number;
  isUnread?: boolean;
  satelliteEnabled?: boolean;

  private constructor(conversationDetails: ConversationDetails) {
    const {
      conversation = [],
      conversationId,
      hasUnreadMessage,
      isMultifamily,
      mostRecentMessageTimestampMs,
    } = conversationDetails;

    // Determine the last sender
    this.lastSender = conversation.slice(-1)[0].isMessageOwner === true ? 'user' : 'landlord';

    this.id = conversationId;
    this.lastMessageTimestamp = mostRecentMessageTimestampMs;
    this.isUnread = hasUnreadMessage;
    this.satelliteEnabled = !isMultifamily; // Disable for MF listings. They currently do not use Satellite.
  }

  // Static factory method to create an instance or return null
  static create(conversationDetails: ConversationDetails): Conversation | null {
    const { conversation = [] } = conversationDetails;

    // If a conversation exists between a landlord and tenant, the API should always return
    // a conversation array of size 1, featuring the most recent message in the conversation
    // chain.
    if (isEmpty(conversation)) {
      // If conversation is empty, return null as per existing logic
      return null;
    }

    // If not empty, create and return an instance of Conversation
    return new Conversation(conversationDetails);
  }
}

export default Conversation;
