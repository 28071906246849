import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { yieldCallback } from '@zillow/yield-callback';

import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import AppActions from 'app/shared/flux/actions/AppActions';

import ConversationActions from 'app/shared/flux/actions/ConversationActions';
import HeaderNavMdAndUp from 'app/shared/modules/navigation/header/HeaderNavMdAndUp';
import HeaderNavMobile from 'app/shared/modules/navigation/header/HeaderNavMobile';
import SkipToMain from 'app/shared/modules/SkipToMain';
import routeUtils from 'app/shared/utils/routeUtils';
import { zindex } from 'app/shared/styles/_zindex';
import { colors } from 'app/shared/styles/_colors';
import { headerHeights } from 'app/shared/styles/_variables';
import { viewports } from 'app/shared/styles/_breakpoints';

const StyledHeaderNav = styled.div`
  height: ${headerHeights['mobile-header-height']};
  @media ${viewports['md-and-up']} {
    height: ${headerHeights['desktop-header-height']};
  }
`;

const StyledHeaderNavWrapper = styled.div`
  left: 0;
  background: ${colors['$hpx-white']};
  z-index: ${zindex['$z-index-loader']};
  right: 0;
  top: 0;
  position: fixed;
`;

interface RootState {
  location: { current: any };
  app: { device: { screenWidth: string }; hamburger: boolean };
  user: { loggedIn: boolean; unreadMsgCount: number; info: any };
}

const HeaderNav: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const savedActiveElement = React.useRef<HTMLElement | null>(null);

  const { loggedIn, userInfo, isMobile, unreadMsgCount } = useSelector((state: RootState) => ({
    loggedIn: state.user.loggedIn,
    userInfo: state.user.info,
    isMobile: state.app.device.screenWidth === 'sm',
    unreadMsgCount: state.user.unreadMsgCount,
  }));

  const pathname = location.pathname;

  const fetchMessageCount = useCallback(() => {
    dispatch(ConversationActions.fetchMessageCount());
  }, [dispatch]);

  useEffect(() => {
    fetchMessageCount();
  }, [fetchMessageCount, pathname]);

  const handleOpenHamburgerNav = yieldCallback(() => {
    savedActiveElement.current = document.activeElement as HTMLElement;
    dispatch(AppActions.activateHamburger());
    dispatch(analyticsEvent(gaEvents.HAMBURGER_NAV_OPEN));
  });

  const handleCloseHamburgerNav = () => {
    if (savedActiveElement.current) {
      savedActiveElement.current.focus();
    }
    dispatch(AppActions.deactivateHamburger());
    savedActiveElement.current = null;
  };

  const isPadOrBuildingUrl = routeUtils.isPadOrBuildingUrl(pathname);

  if (isPadOrBuildingUrl && isMobile) {
    return null;
  }

  return (
    <>
      <StyledHeaderNav>
        <SkipToMain />
        <StyledHeaderNavWrapper>
          {isMobile ? (
            <HeaderNavMobile
              onCloseHamburgerNav={handleCloseHamburgerNav}
              onOpenHamburgerNav={handleOpenHamburgerNav}
              unreadMsgCount={unreadMsgCount}
            />
          ) : (
            <HeaderNavMdAndUp
              loggedIn={loggedIn}
              userInfo={userInfo}
              onOpenHamburgerNav={handleOpenHamburgerNav}
              onCloseHamburgerNav={handleCloseHamburgerNav}
              unreadMsgCount={unreadMsgCount}
            />
          )}
        </StyledHeaderNavWrapper>
      </StyledHeaderNav>
      <div id="skip-topnav-target" tabIndex={-1} />
    </>
  );
};

export default HeaderNav;
